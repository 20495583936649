import React, { useEffect, useState } from 'react';
import Spinner from '../components/common/Spinner';
import { redirectWithParams } from '../utils/Utils';
import { navigate } from 'gatsby';

function MyAsurionRedirect() {
    const [isGoingBack, setIsGoingBack] = useState<boolean>(false);

    useEffect(() => {
        if (isGoingBack) {
            navigate(-1);
        } else {
            setTimeout(
                () => (window.location.href = redirectWithParams(process.env.GATSBY_MYASURION_PROFILE_URL)),
                1000
            );
            setIsGoingBack(true);
        }
    }, []);
    return <Spinner />;
}

export default MyAsurionRedirect;
